<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-detalle-tepprogramacion"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white" v-if="data_solicitud.solicitud">
              Detalle Solicitud
            </h4>

            <h4 class="modal-title text-white" v-if="data_solicitud.turnos">
              Detalle Turno
            </h4>

            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Solicitudes"
                  data-toggle="tab"
                  v-if="data_solicitud.solicitud"
                  href="#Solicitudes"
                  >Solicitud</a
                >
                <a
                  class="nav-link active"
                  id="tab-Solicitudes"
                  data-toggle="tab"
                  v-if="data_solicitud.turnos"
                  href="#Turno"
                  >Turno</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Viajes"
                  data-toggle="tab"
                  v-if="data_solicitud.solicitud"
                  href="#Viajes"
                  >Viaje
                </a>
                <a
                  class="nav-link"
                  id="tab-Viajes"
                  data-toggle="tab"
                  v-if="data_solicitud.turnos"
                  href="#Odometros"
                  >Odometros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade active show"
                id="Solicitudes"
                v-if="data_solicitud.solicitud != null"
              >
                <div class="card-body">
                  <!-- Detalle de la Solicitud -->
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="position-relative p-3 m-2 bg-light"
                        style="font-size: 0.85em"
                      >
                        <div class="ribbon-wrapper ribbon-lg">
                          <div
                            class="ribbon"
                            :class="
                              data_solicitud.solicitud.estado == 1
                                ? 'badge-success'
                                : data_solicitud.solicitud.estado == 2
                                ? 'badge-warning'
                                : data_solicitud.solicitud.estado == 2
                                ? 'badge-primary'
                                : 'badge-secondary'
                            "
                          >
                            <small>{{
                              data_solicitud.solicitud.nEstado
                            }}</small>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-1">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_solicitud.solicitud.id }}
                              </h5>
                              <span class="description-text text-secondary"
                                ># Solicitud</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.solicitud.user
                                    ? data_solicitud.solicitud.user.name
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Usuario</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.solicitud.tipo_vehiculo
                                    ? data_solicitud.solicitud.tipo_vehiculo
                                        .nombre
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Tipo Vehiculo</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.solicitud
                                    .fecha_hora_inicio_servicio
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Fecha Inicio</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.solicitud
                                    .fecha_hora_fin_servicio
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Fecha Fin</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_solicitud.solicitud.AFE }}
                              </h5>
                              <span class="description-text text-secondary"
                                >AFE</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_solicitud.solicitud.CECO }}
                              </h5>
                              <span class="description-text text-secondary"
                                >CECO</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="data_solicitud.solicitud.funcionario_afe"
                              >
                                {{
                                  data_solicitud.solicitud.funcionario_afe
                                    .nombres
                                }}
                                {{
                                  data_solicitud.solicitud.funcionario_afe
                                    .apellidos
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Dueño AFE</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5
                                class="description-header"
                                v-if="
                                  data_solicitud.solicitud.funcionario_gerente
                                "
                              >
                                {{
                                  data_solicitud.solicitud.funcionario_gerente
                                    .nombres
                                }}
                                {{
                                  data_solicitud.solicitud.funcionario_gerente
                                    .apellidos
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Gerente de Area</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_solicitud.solicitud.origen_JSON }}
                              </h5>
                              <h5 class="description-header">
                                <small>{{
                                  data_solicitud.solicitud.direccion_origen
                                }}</small>
                              </h5>
                              <h5 class="description-header">
                                <small>
                                  Lat.
                                  {{ data_solicitud.solicitud.lat_origen }} |
                                  Lng.
                                  {{ data_solicitud.solicitud.long_origen }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Origen</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_solicitud.solicitud.destino_JSON }}
                              </h5>
                              <h5 class="description-header">
                                <small>{{
                                  data_solicitud.solicitud.direccion_destino
                                }}</small>
                              </h5>
                              <h5 class="description-header">
                                <small>
                                  Lat.
                                  {{ data_solicitud.solicitud.lat_destino }} |
                                  Lng.
                                  {{ data_solicitud.solicitud.long_destino }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Destino</span
                              >
                            </div>
                          </div>
                          <!-- Vehículo -->
                          <div
                            class="
                              col-12 col-sm-6 col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                            v-if="data_solicitud.solicitud.vehiculo_id != null"
                          >
                            <div class="card bg-white d-flex flex-fill">
                              <div
                                class="card-header text-muted border-bottom-0"
                              >
                                Vehículo
                              </div>
                              <div class="card-body pt-0">
                                <div class="row">
                                  <div
                                    :class="
                                      data_solicitud.solicitud.vehiculo
                                        .link_fotografia
                                        ? 'col-md-7'
                                        : 'col-md-12'
                                    "
                                  >
                                    <h2 class="lead">
                                      <b>{{
                                        data_solicitud.solicitud.vehiculo.placa
                                      }}</b>
                                    </h2>
                                    <p
                                      class="text-muted text-sm"
                                      v-if="
                                        data_solicitud.solicitud.transportadora
                                      "
                                    >
                                      <b>Transportadora: </b>
                                      {{
                                        data_solicitud.solicitud.transportadora
                                          .razon_social
                                      }}
                                    </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-car"></i
                                        ></span>
                                        Tipo Vehículo:
                                        {{
                                          data_solicitud.solicitud.tipo_vehiculo
                                            .nombre
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    class="col-md-5 text-center"
                                    v-if="
                                      data_solicitud.solicitud.vehiculo
                                        .link_fotografia
                                    "
                                  >
                                    <img
                                      :src="
                                        uri_docs +
                                          data_solicitud.solicitud.vehiculo
                                            .link_fotografia
                                      "
                                      alt="Vehículo"
                                      class="img-circle img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="
                              col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                          >
                            <span> no hay vehiculo</span>
                          </div>

                          <!-- Conductor -->
                          <div
                            class="
                              col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                            v-if="data_solicitud.solicitud.conductor_id != null"
                          >
                            <div class="card bg-white d-flex flex-fill">
                              <div
                                class="card-header text-muted border-bottom-0"
                              >
                                Conductor
                              </div>
                              <div class="card-body pt-0">
                                <div class="row">
                                  <div
                                    :class="
                                      data_solicitud.solicitud.conductor
                                        .link_fotografia
                                        ? 'col-md-7'
                                        : 'col-md-12'
                                    "
                                  >
                                    <h2 class="lead">
                                      <b
                                        >{{
                                          data_solicitud.solicitud.conductor
                                            .nombres
                                        }}
                                        {{
                                          data_solicitud.solicitud.conductor
                                            .apellidos
                                        }}
                                      </b>
                                    </h2>
                                    <p class="text-muted text-sm">
                                      <b>Documento: </b>
                                      {{
                                        data_solicitud.solicitud.conductor
                                          .numero_documento
                                      }}
                                    </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-envelope"></i
                                        ></span>
                                        E-mail:
                                        {{
                                          data_solicitud.solicitud.conductor
                                            .email
                                        }}
                                      </li>
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-mobile"></i
                                        ></span>
                                        Celular Principal:
                                        {{
                                          data_solicitud.solicitud.conductor
                                            .celular_principal
                                        }}
                                      </li>
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-phone"></i
                                        ></span>
                                        Celular Alterno:
                                        {{
                                          data_solicitud.solicitud.conductor
                                            .celular_alterno
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    class="col-md-5 text-center"
                                    v-if="
                                      data_solicitud.solicitud.conductor
                                        .link_fotografia
                                    "
                                  >
                                    <img
                                      :src="
                                        uri_docs +
                                          data_solicitud.solicitud.conductor
                                            .link_fotografia
                                      "
                                      alt="Conductor"
                                      class="img-circle img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="
                              col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                          >
                            <span> no hay conductor </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade active show"
                id="Turno"
                v-if="data_solicitud.turnos != null"
              >
                <div class="card-body">
                  <!-- Detalle del turno -->
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="position-relative p-3 m-2 bg-light"
                        style="font-size: 0.85em"
                      >
                        <div class="ribbon-wrapper ribbon-lg">
                          <div
                            class="ribbon"
                            :class="
                              data_solicitud.turnos.estado == 1
                                ? 'badge-success'
                                : data_solicitud.turnos.estado == 2
                                ? 'badge-primary'
                                : 'badge-warning'
                            "
                          >
                            <small>{{ data_solicitud.turnos.nEstado }}</small>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-1">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_solicitud.turnos.id }}
                              </h5>
                              <span class="description-text text-secondary"
                                ># Turno</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_solicitud.turnos.vehiculo.placa }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Vehiculo</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_solicitud.turnos.fecha_ini }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Fecha Inicio</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.turnos.fecha_fin
                                    ? data_solicitud.turnos.fecha_fin
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Fecha Fin</span
                              >
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                <small>
                                  Lat.
                                  {{ data_solicitud.turnos.lat_ini_conductor }}
                                  | Lng.
                                  {{ data_solicitud.turnos.lon_ini_conductor }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Origen Del Conductor</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                <small>
                                  Lat.
                                  {{ data_solicitud.turnos.lat_ini_vehiculo }} |
                                  Lng.
                                  {{ data_solicitud.turnos.lon_ini_vehiculo }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Origen Del Vehiculo</span
                              >
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                <small>
                                  Lat.
                                  {{ data_solicitud.turnos.lat_fin_conductor }}
                                  | Lng.
                                  {{ data_solicitud.turnos.lon_fin_conductor }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Destino Del Conductor</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                <small>
                                  Lat.
                                  {{ data_solicitud.turnos.lat_fin_vehiculo }} |
                                  Lng.
                                  {{ data_solicitud.turnos.lon_fin_vehiculo }}
                                </small>
                              </h5>
                              <span class="description-text text-secondary"
                                >Destino Del Vehículo</span
                              >
                            </div>
                          </div>
                          <!-- Datos resumen del la gestión el turno -->
                          <div
                            class="col-md-12"
                            v-if="
                              data_solicitud.turnos
                                .tep_preliquidacion_internos &&
                                data_solicitud.turnos
                                  .tep_preliquidacion_internos.length > 0
                            "
                          >
                            <div class="row">
                              <div class="col-md-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_solicitud.turnos
                                        .tep_preliquidacion_internos
                                        ? data_solicitud.turnos
                                            .tep_preliquidacion_internos[0]
                                            .km_recorridos
                                        : ""
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Kilómetros Recorridos</span
                                  >
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_solicitud.turnos
                                        .tep_preliquidacion_internos[0]
                                        .horas_trabajadas
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Horas Trabajadas</span
                                  >
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_solicitud.turnos
                                        .tep_preliquidacion_internos[0].km_add
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Kilómetros Adicionales</span
                                  >
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_solicitud.turnos
                                        .tep_preliquidacion_internos[0]
                                        .horas_extra
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Horas Extra</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12" v-else>
                            <div class="row">
                              <div
                                class="col-md-3"
                                v-if="
                                  data_solicitud.turnos.odometro_ini &&
                                    data_solicitud.turnos.odometro_fin
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      data_solicitud.turnos.odometro_fin -
                                        data_solicitud.turnos.odometro_ini
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Kilómetros Recorridos</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-md-3"
                                v-if="
                                  data_solicitud.turnos.fecha_ini &&
                                    data_solicitud.turnos.fecha_fin
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      getHoras(
                                        data_solicitud.turnos.fecha_ini,
                                        data_solicitud.turnos.fecha_fin
                                      )
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Horas Trabajadas</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row justify-content-md-center">
                    <div class="col-md-12">
                      <div class="card card-info">
                        <div class="card-header">
                          <h3 class="card-title">Vehiculo y Conductor</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <!-- Vehículo -->
                            <div
                              class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                              v-if="data_solicitud.turnos.vehiculo_id != null"
                            >
                              <div class="card bg-white d-flex flex-fill">
                                <div
                                  class="card-header text-muted border-bottom-0"
                                >
                                  Vehículo
                                </div>
                                <div class="card-body pt-0">
                                  <div class="row">
                                    <div
                                      :class="
                                        data_solicitud.turnos.vehiculo
                                          .link_fotografia
                                          ? 'col-md-7'
                                          : 'col-md-12'
                                      "
                                    >
                                      <h2 class="lead">
                                        <b>{{
                                          data_solicitud.turnos.vehiculo.placa
                                        }}</b>
                                      </h2>

                                      <ul class="ml-4 mb-0 fa-ul text-muted">
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-car"></i
                                          ></span>
                                          Tipo Vehículo:
                                          {{
                                            data_solicitud.turnos.vehiculo
                                              .tipo_vehiculo.nombre
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="col-md-5 text-center"
                                      v-if="
                                        data_solicitud.turnos.vehiculo
                                          .link_fotografia
                                      "
                                    >
                                      <img
                                        :src="
                                          uri_docs +
                                            data_solicitud.turnos.vehiculo
                                              .link_fotografia
                                        "
                                        alt="Vehículo"
                                        class="img-circle img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                            >
                              <div class="small-box bg-info">
                                <div class="inner">
                                  <h3>No hay vehiculo</h3>
                                </div>
                                <div class="icon">
                                  <i class="fas fa-car"></i>
                                </div>
                              </div>
                            </div>
                            <!-- CONDUCTOR -->
                            <div
                              class="
                                col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                              v-if="data_solicitud.turnos.conductor_id != null"
                            >
                              <div class="card bg-white d-flex flex-fill">
                                <div
                                  class="card-header text-muted border-bottom-0"
                                >
                                  Conductor
                                </div>
                                <div class="card-body pt-0">
                                  <div class="row">
                                    <div
                                      :class="
                                        data_solicitud.turnos.conductor
                                          .link_fotografia
                                          ? 'col-md-7'
                                          : 'col-md-12'
                                      "
                                    >
                                      <h2 class="lead">
                                        <b
                                          >{{
                                            data_solicitud.turnos.conductor
                                              .nombres
                                          }}
                                          {{
                                            data_solicitud.turnos.conductor
                                              .apellidos
                                          }}
                                        </b>
                                      </h2>
                                      <p class="text-muted text-md">
                                        <b>Documento: </b>
                                        {{
                                          data_solicitud.turnos.conductor
                                            .numero_documento
                                        }}
                                      </p>
                                      <ul class="ml-4 mb-0 fa-ul text-muted">
                                        <li class="lead">
                                          <span class="fa-li"
                                            ><i
                                              class="fas fa-lg fa-envelope"
                                            ></i
                                          ></span>
                                          E-mail:
                                          {{
                                            data_solicitud.turnos.conductor
                                              .email
                                          }}
                                        </li>
                                        <li class="lead">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-mobile"></i
                                          ></span>
                                          Celular Principal:
                                          {{
                                            data_solicitud.turnos.conductor
                                              .celular_principal
                                          }}
                                        </li>
                                        <li class="lead">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-phone"></i
                                          ></span>
                                          Celular Alterno:
                                          {{
                                            data_solicitud.turnos.conductor
                                              .celular_alterno
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="col-md-5 text-center"
                                      v-if="
                                        data_solicitud.turnos.conductor
                                          .link_fotografia
                                      "
                                    >
                                      <img
                                        :src="
                                          uri_docs +
                                            data_solicitud.turnos.conductor
                                              .link_fotografia
                                        "
                                        alt="Conductor"
                                        class="img-circle img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="
                                col-12 col-sm-6 col-md-6
                                d-flex
                                align-items-stretch
                                flex-column
                              "
                            >
                              <div class="small-box bg-info">
                                <div class="inner">
                                  <h3>No hay conductor</h3>
                                </div>
                                <div class="icon">
                                  <i class="fas fa-user"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                v-if="data_solicitud.viaje != null"
                id="Viajes"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="position-relative p-3 m-2 bg-light"
                        style="font-size: 0.85em"
                      >
                        <div class="ribbon-wrapper">
                          <div class="ribbon bg-info">Viaje</div>
                        </div>
                        <div class="row">
                          <!-- Vehículo -->
                          <div
                            class="
                              col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                          >
                            <div class="card bg-white d-flex flex-fill">
                              <div
                                class="card-header text-muted border-bottom-0"
                              >
                                Vehículo
                              </div>
                              <div class="card-body pt-0">
                                <div class="row">
                                  <div
                                    :class="
                                      data_solicitud.viaje.vehiculo
                                        .link_fotografia
                                        ? 'col-md-7'
                                        : 'col-md-12'
                                    "
                                  >
                                    <h2 class="lead">
                                      <b>{{
                                        data_solicitud.viaje.vehiculo.placa
                                      }}</b>
                                    </h2>
                                    <p
                                      class="text-muted text-sm"
                                      v-if="data_solicitud.viaje.transportadora"
                                    >
                                      <b>Transportadora: </b>
                                      {{
                                        data_solicitud.viaje.transportadora
                                          .razon_social
                                      }}
                                    </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-car"></i
                                        ></span>
                                        Tipo Vehículo:
                                        {{
                                          data_solicitud.viaje.vehiculo
                                            .tipo_vehiculo.nombre
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    class="col-md-5 text-center"
                                    v-if="
                                      data_solicitud.viaje.vehiculo
                                        .link_fotografia
                                    "
                                  >
                                    <img
                                      :src="
                                        uri_docs +
                                          data_solicitud.viaje.vehiculo
                                            .link_fotografia
                                      "
                                      alt="Vehículo"
                                      class="img-bordered img-rounded img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Conductor -->
                          <div
                            class="
                              col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                          >
                            <div class="card bg-white d-flex flex-fill">
                              <div
                                class="card-header text-muted border-bottom-0"
                              >
                                Conductor
                              </div>
                              <div class="card-body pt-0">
                                <div class="row">
                                  <div
                                    :class="
                                      data_solicitud.viaje.conductor
                                        .link_fotografia
                                        ? 'col-md-7'
                                        : 'col-md-12'
                                    "
                                  >
                                    <h2 class="lead">
                                      <b
                                        >{{
                                          data_solicitud.viaje.conductor.nombres
                                        }}
                                        {{
                                          data_solicitud.viaje.conductor
                                            .apellidos
                                        }}
                                      </b>
                                    </h2>
                                    <p
                                      v-if="data_solicitud.encuesta"
                                      class="text-muted text-sm"
                                    >
                                      <b>Calificación: </b>
                                      {{ data_solicitud.encuesta.calificacion }}
                                      <i class="fas fa-star"></i>
                                    </p>
                                    <p v-else class="text-muted text-sm">
                                      <b>Calificación: </b>
                                      Sin calificación
                                    </p>
                                    <p class="text-muted text-sm">
                                      <b>Documento: </b>
                                      {{
                                        data_solicitud.viaje.conductor
                                          .numero_documento
                                      }}
                                    </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-envelope"></i
                                        ></span>
                                        E-mail:
                                        {{
                                          data_solicitud.viaje.conductor.email
                                        }}
                                      </li>
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-mobile"></i
                                        ></span>
                                        Celular Principal:
                                        {{
                                          data_solicitud.viaje.conductor
                                            .celular_principal
                                        }}
                                      </li>
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-phone"></i
                                        ></span>
                                        Celular Alterno:
                                        {{
                                          data_solicitud.viaje.conductor
                                            .celular_alterno
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    class="col-md-5 text-center"
                                    v-if="
                                      data_solicitud.viaje.conductor
                                        .link_fotografia
                                    "
                                  >
                                    <img
                                      :src="
                                        uri_docs +
                                          data_solicitud.viaje.conductor
                                            .link_fotografia
                                      "
                                      alt="Conductor"
                                      class="img-bordered img-rounded img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div
                        class="position-relative p-3 m-2 bg-light"
                        style="font-size: 0.85em"
                      >
                        <div class="ribbon-wrapper">
                          <div class="ribbon bg-info">Turno</div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.tep_turno
                                    ? data_solicitud.tep_turno.id
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Turno</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div
                        class="position-relative p-3 m-2 bg-light"
                        style="font-size: 0.85em"
                      >
                        <div class="ribbon-wrapper">
                          <div class="ribbon bg-info">Tarifa</div>
                        </div>
                        <div class="row">
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.viaje.tarifa
                                    ? data_solicitud.viaje.tarifa.tarifa
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Valor</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.viaje.tarifa
                                    ? data_solicitud.viaje.tarifa.nHorario
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Horario</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.viaje.tarifa
                                    ? data_solicitud.viaje.tarifa
                                        .valor_horas_extras
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Hora Extra</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.viaje.tarifa
                                    ? data_solicitud.viaje.tarifa
                                        .kilometros_iniciales
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Kilómeto Inicial</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.viaje.tarifa
                                    ? data_solicitud.viaje.tarifa
                                        .valor_kilometro_pavimentado
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >$ Kilómetro Pavimentado</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_solicitud.viaje.tarifa
                                    ? data_solicitud.viaje.tarifa
                                        .valor_kilometro_destapado
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >$ Kilómetro Destapado</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="position-relative p-3 m-2 bg-light">
                        <div class="ribbon-wrapper ribbon-lg">
                          <div class="ribbon bg-info">Odómetro</div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div
                                class="
                                  col-md-6
                                  d-flex
                                  align-items-stretch
                                  flex-column
                                "
                                v-if="data_solicitud.viaje.odometro_ini"
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div
                                    class="
                                      card-header
                                      text-muted
                                      border-bottom-0
                                    "
                                  >
                                    Odómetro Inicial
                                  </div>
                                  <div class="card-body pt-0">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <h2 class="lead">
                                          <b>{{
                                            data_solicitud.viaje.odometro_ini
                                          }}</b>
                                        </h2>
                                      </div>
                                      <div
                                        class="col-md-8 text-center"
                                        v-if="
                                          data_solicitud.viaje.foto_odometro_ini
                                        "
                                      >
                                        <img
                                          @click="
                                            getImage(
                                              data_solicitud.viaje
                                                .foto_odometro_ini
                                            )
                                          "
                                          :src="
                                            uri_docs +
                                              data_solicitud.viaje
                                                .foto_odometro_ini
                                          "
                                          alt="Odómetro Inicial"
                                          class="
                                            img-bordered img-rounded img-fluid
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="
                                  col-md-6
                                  d-flex
                                  align-items-stretch
                                  flex-column
                                "
                                v-if="data_solicitud.viaje.odometro_fin"
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div
                                    class="
                                      card-header
                                      text-muted
                                      border-bottom-0
                                    "
                                  >
                                    Odómetro Final
                                  </div>
                                  <div class="card-body pt-0">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <h2 class="lead">
                                          <b>{{
                                            data_solicitud.viaje.odometro_fin
                                          }}</b>
                                        </h2>
                                      </div>
                                      <div
                                        class="col-md-8 text-center"
                                        v-if="
                                          data_solicitud.viaje.foto_odometro_fin
                                        "
                                      >
                                        <img
                                          @click="
                                            getImage(
                                              data_solicitud.viaje
                                                .foto_odometro_fin
                                            )
                                          "
                                          :src="
                                            uri_docs +
                                              data_solicitud.viaje
                                                .foto_odometro_fin
                                          "
                                          alt="Odómetro Final"
                                          class="
                                            img-bordered img-rounded img-fluid
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="tab-pane" id="Viajes">
                <div class="card-body">
                  <div class="info-box bg-gradient-warning">
                    <span class="info-box-icon"
                      ><i class="fas fa-exclamation-triangle"></i
                    ></span>
                    <div class="info-box-content">
                      <span class="info-box-text"
                        >No tiene un viaje asignado</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="Odometros">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card-body">
                        <!-- odometro -->
                        <div class="row justify-content-md-center">
                          <div
                            v-if="
                              data_solicitud.turnos !== null &&
                                data_solicitud.turnos !== undefined
                            "
                          >
                            <div
                              class="col-md-12"
                              v-if="
                                data_solicitud.turnos.odometro_ini != null ||
                                  data_solicitud.turnos.odometro_fin != null
                              "
                            >
                              <div class="position-relative p-3 m-2 bg-light">
                                <div class="ribbon-wrapper ribbon-lg">
                                  <div class="ribbon bg-primary">Odometro</div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div
                                        class="
                                        col-sm-6
                                        d-flex
                                        align-items-stretch
                                        flex-column
                                      "
                                        v-if="
                                          data_solicitud.turnos.odometro_ini
                                        "
                                      >
                                        <div
                                          class="card bg-white d-flex flex-fill"
                                        >
                                          <div
                                            class="
                                            card-header
                                            text-muted
                                            border-bottom-0
                                          "
                                          >
                                            Odómetro Inicial
                                          </div>
                                          <div class="card-body pt-0">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <h3 class="lead">
                                                  <b>{{
                                                    data_solicitud.turnos
                                                      .odometro_ini
                                                  }}</b>
                                                </h3>
                                              </div>
                                              <div
                                                class="col-md-8 text-center"
                                                v-if="
                                                  data_solicitud.turnos
                                                    .link_odometro_ini
                                                "
                                              >
                                                <img
                                                  :src="
                                                    uri_docs +
                                                      data_solicitud.turnos
                                                        .link_odometro_ini
                                                  "
                                                  alt="Odómetro Inicial"
                                                  class="
                                                  img-bordered
                                                  img-rounded
                                                  img-fluid
                                                "
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="
                                        col-md-6
                                        d-flex
                                        align-items-stretch
                                        flex-column
                                      "
                                        v-if="
                                          data_solicitud.turnos.odometro_fin
                                        "
                                      >
                                        <div
                                          class="card bg-white d-flex flex-fill"
                                        >
                                          <div
                                            class="
                                            card-header
                                            text-muted
                                            border-bottom-0
                                          "
                                          >
                                            Odómetro Final
                                          </div>
                                          <div class="card-body pt-0">
                                            <div class="row">
                                              <div class="col-md-5">
                                                <h3 class="lead">
                                                  <b>{{
                                                    data_solicitud.turnos
                                                      .odometro_fin
                                                  }}</b>
                                                </h3>
                                              </div>
                                              <div
                                                class="col-md-8 text-center"
                                                v-if="
                                                  data_solicitud.turnos
                                                    .link_odometro_fin
                                                "
                                              >
                                                <img
                                                  :src="
                                                    uri_docs +
                                                      data_solicitud.turnos
                                                        .link_odometro_fin
                                                  "
                                                  alt="Odómetro Final"
                                                  class="
                                                  img-bordered
                                                  img-rounded
                                                  img-fluid
                                                "
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            v-else
                            class="
                              col-md-12
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                          >
                            <div
                              class="small-box bg-info"
                              style="min-height: 100px"
                            >
                              <div class="inner">
                                <h3>No hay Odometros</h3>
                              </div>
                              <div class="icon">
                                <i class="far fa-eye-slash"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TepProgramacionDetalle",
  components: {},
  data() {
    return {
      data_solicitud: {
        solicitud: {},
      },
      soliditud_id: null,
      traza_viaje: {},
      origen: {},
      destino: {},
      center: {},
      zoom: 10,
      markers: [],
      markers_viaje: [],
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    async llenar_modal(item) {
      this.data_solicitud = item;
    },
    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },
    getHoras(fecha_ini, fecha_fin) {
      const inicio = new Date(fecha_ini).getTime();
      const fin = new Date(fecha_fin).getTime();
      const result = (fin - inicio) / (1000 * 60 * 60);
      return result.toFixed(2);
    },
  },
};
</script>
